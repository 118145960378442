import { useGAKnownUserInit, useGAPublicInit } from './useGAInit';
import useAmplitudeKnownUserInit, {
  useAmplitudePublicInit,
} from './useAmplitudeInit';
import { PublicUserTracking } from './types/PublicUserTracking';

export function useAnalyticsKnownUserInit(): void {
  useAmplitudeKnownUserInit();
  useGAKnownUserInit();
}

export function useAnalyticsPublicInit(
  isLoading: boolean,
  publicUserTrackingData: PublicUserTracking,
): void {
  useAmplitudePublicInit(publicUserTrackingData);
  useGAPublicInit(isLoading, publicUserTrackingData);
}
