import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { FEATURES, useHasFeatureFlag } from '../../../hooks/useFeatureFlags';

export const TrackerWidgetHead: FC = () => {
  const hasTrackingWidget = useHasFeatureFlag(FEATURES.TrackingWidget);

  if (!hasTrackingWidget) return <></>;

  return (
    <Helmet>
      <script>
        {`(function(w,i,d,g,e,t){w["WidgetTrackerObject"]=g;(w[g]=w[g]||function()
{(w[g].q=w[g].q||[]).push(arguments);}),(w[g].ds=1*new Date());(e="script"),
(t=d.createElement(e)),(e=d.getElementsByTagName(e)[0]);t.async=1;t.src=i;
e.parentNode.insertBefore(t,e);})
(window,"https://widgetbe.com/agent",document,"widgetTracker");
window.widgetTracker("create", "WT-UNVRMKAD");
window.widgetTracker("send", "pageview");`}
      </script>
    </Helmet>
  );
};
