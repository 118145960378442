import { useEffect } from 'react';

import { useAgentDetails } from '../../hooks/query/useAgentDetails';
import { useTokenData } from '../../providers/Authentication/useTokenData';
import { useAccountDetails } from '../../providers/Authentication/useAccountDetails';
import { PublicUserTracking } from './types/PublicUserTracking';

function useIdentifyParentAccount(
  isLoading: boolean,
  parentAccountId?: number,
) {
  useEffect(() => {
    if (isLoading) return;

    window.dataLayer?.push?.({
      event: 'parentAccount',
      parentAccount: parentAccountId,
    });
  }, [isLoading, parentAccountId]);
}

function useIdentifyAgentName(isLoading: boolean, agentName?: string) {
  useEffect(() => {
    if (isLoading) return;

    window.dataLayer?.push?.({
      event: 'agentNamePageLoad',
      agentName: agentName,
    });
  }, [isLoading, agentName]);
}

export function useGAKnownUserInit(): void {
  const { data: agentData, isLoading: agentIsLoading } = useAgentDetails();
  const { data: tokenData, isLoading: tokenLoading } = useTokenData();
  const { data: account, isLoading: accountIsLoading } = useAccountDetails();

  useEffect(() => {
    if (tokenLoading) return;

    window.dataLayer?.push?.({
      event: 'accountNamePageLoad',
      accountName: tokenData?.accountId,
    });
  }, [tokenData?.accountId, tokenLoading]);

  const agentFullName = !agentData?.Name?.firstName
    ? ''
    : `${agentData?.Name.firstName} ${agentData?.Name.lastName}`;

  useIdentifyAgentName(agentIsLoading, agentFullName);
  useIdentifyParentAccount(accountIsLoading, account?.parentAccountId);
}

export function useGAPublicInit(
  isLoading: boolean,
  publicUserTrackingData: PublicUserTracking,
): void {
  useIdentifyAgentName(isLoading, publicUserTrackingData.agentName);
  useIdentifyParentAccount(isLoading, publicUserTrackingData?.parentAccountId);
}
