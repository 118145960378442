import { useAccountDetails } from '../providers/Authentication/useAccountDetails';

const REMAX_SUPER_ACCOUNT_ID = 6207;

export function useIsOperatingUnderRemax() {
  const { data: accountDetails } = useAccountDetails();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return accountDetails?.parentAccountId === REMAX_SUPER_ACCOUNT_ID;
}

export const REMAX_SUPER_ACCOUNT_URL_PARAM = `superaccount=${REMAX_SUPER_ACCOUNT_ID}`;
