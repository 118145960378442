import React from 'react';
import { useTranslation } from 'react-i18next';
import { AgentDetails } from '../components/AgentDetails/AgentDetails';
import { GetApp } from '../components/GetApp/GetApp';
import { MobileImages } from '../components/MobileImages/MobileImages';

import S from './AppDeeplinkWebView.style';
import { Footer } from './Footer/Footer';

export const AppDeeplinkWebView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <S.Page>
      <S.Main>
        <AgentDetails />
        <S.Title>{t('mobileAppDeepLink.title')}</S.Title>
        <S.GetAppWrapper>
          <GetApp />
        </S.GetAppWrapper>
      </S.Main>

      <S.FooterWrapper>
        <Footer />
        <S.ImagesContainer>
          <MobileImages />
        </S.ImagesContainer>
      </S.FooterWrapper>
    </S.Page>
  );
};
