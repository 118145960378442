import React, { useMemo } from 'react';
import { ThemeProvider } from '@emotion/react';

import { theme as buildTheme } from '../../constants';
import { BrandingDetailsExtended } from '../../types';
import { useIsMobileView } from '../../hooks/useIsMobileView';
import { useBrandingAndAgent } from './hooks/useBrandingAndAgent';
import { useAnalyticsPublicInit } from '../../features/analytics';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { AppDeeplinkWebView } from './AppDeeplinkWebView/AppDeeplinkWebView';
import { GlobalStyling } from '../../components/global-style/global-style.style';
import { AppDeeplinkMobileView } from './AppDeeplinkMobileView/AppDeeplinkMobileView';

const ShareMobileAppPage: React.FC = () => {
  useSetDocumentTitle('mobileAppDeepLink.pageTitle');
  const isMobileView = useIsMobileView();
  const { data, isLoading } = useBrandingAndAgent();

  useAnalyticsPublicInit(isLoading, {
    parentAccountId: data?.parentAccountId,
    agentName: data?.agentName,
  });

  const theme = useMemo(() => {
    let themeColorHex = data?.brandingColor ?? '#000';
    if (themeColorHex && !themeColorHex.startsWith('#')) {
      themeColorHex = `#${data?.brandingColor}`;
    }

    return buildTheme({ themeColorHex } as BrandingDetailsExtended);
  }, [data]);

  if (isLoading) return null;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyling />
      {isMobileView ? <AppDeeplinkMobileView /> : <AppDeeplinkWebView />}
    </ThemeProvider>
  );
};

export default ShareMobileAppPage;
