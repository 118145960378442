import { constructUrl } from '../utils/url-helper';

const apiRoutes = {
  auth: {
    keyDecode: () => '/auth/keyDecode',
    token: () => '/auth/token',
    tokenNew: () => '/auth/token/new',
    login: () => '/auth/login',
    forgotPassword: () => '/auth/forgotPassword',
    resetPassword: () => '/auth/resetPassword',
    updatePassword: () => '/auth/updatePassword',
    register: () => '/auth/register',
    completeUserInfo: () => '/auth/getCompleteUserInformation',
  },
  account: {
    getDetails: () => '/accountDetail',
  },
  chat: {
    getMessageCount: () => '/chatMessagesCount',
    postConversation: () => '/chatConversation',
    readConversation: (id) => `/chatConversation/${id}/read`,
  },
  market: {
    getByArea: (area) => constructUrl('/marketDetail/byArea', area),
    viewd: () => '/marketDetail/viewed',
  },
  homeowner: {
    getByContact: () => `/homeownerDetail/kvContact`,
    patch: () => `/homeownerDetail`,
    deleteAreaOfInterest: (areasOfInterestId, updateKvCoreInd = false) =>
      `/homeownerDetail/areasOfInterest/${areasOfInterestId}?updateKvCoreInd=${updateKvCoreInd}`,
    askQuestion: () => '/homeownerDetail/question',
  },
  savedListings: {
    get: () => '/listing/viewed?saveInd=1',
  },
  savedSearches: {
    get: () => '/savedSearch',
    patch: (searchId) => `/savedSearch?searchId=${searchId}`,
    delete: (searchId, updateKvCoreInd) =>
      `/savedSearch?searchId=${searchId}&updateKvCoreInd=${
        updateKvCoreInd ? 1 : 0
      }`,
    info: (searchId) => `/savedSearch/${searchId}`,
  },
  favoriteListing: {
    post: () => '/homeownerDetail/favoriteListing',
  },
  property: {
    getByHomeownerUuid: () => `/propertyDetail`,
    getByAddress: () => '/propertyDetail/byAddress',
    updatePropertyDetail: () => '/propertyDetail',
  },
  mortgage: {
    get: (propertyUuid) => {
      const query = propertyUuid ? `propertyId=${propertyUuid}` : '';
      return `/mortgageDetail${!query ? '' : '?' + query}`;
    },
    getByAddress: (address) => {
      const params = [];

      params.addressLine1 = address.addressLine1;
      params.locality = address.locality;
      params.countrySubd = address.countrySubd;
      params.postal1 = address.postal1;

      return constructUrl('/mortgageDetail/byAddress', params);
    },
    updateMortgageDetail: (loanId) => `/mortgageDetail/loans/${loanId}`,
    postLoan: () => `/mortgageDetail/loans`,
    deleteLoan: (loanId) => `/mortgageDetail/loans/${loanId}`,
  },
  listing: {
    getRecommended: (searchId) =>
      constructUrl('/recommendedListings', { searchId }),
    get: () => '/listing/detail',
    getListingFeatures: (mlsId, mlsListingId) =>
      `/listing/features/${mlsId}?mlsListingId=${mlsListingId}`,
  },
  insurance: {
    get: () => `/insuranceDetail`,
    updateInsuranceDetail: (policyId) =>
      `/insuranceDetail/policies/${policyId}`,
    postPolicy: () => `/insuranceDetail/policies`,
    deletePolicy: (policyId) => `/insuranceDetail/policies/${policyId}`,
  },
  branding: {
    getByDomain: () => '/brandingDetail',
    initialBrandingDetails: () => '/brandingDetail/initialAgentBrandingDetails',
  },

  mls: {
    getByDomain: (domain) => {
      return constructUrl('/mlsDetail/byDomain', {
        domain,
      });
    },
  },
  polygonDetails: {
    byArea: () => '/polygonDetail/byArea',
    byPolygonId: (polygonId) => `/polygonDetail/${polygonId}`,
  },
  areaSuggestions: {
    get: (query) => `/suggestions?limit=30&term=${query}`,
  },
  listingSnapshot: {
    getEndpoint: () => '/listing/snapshot',
    get: (query) => `/listing/snapshot${!query ? '' : '?' + query}`,
  },
  kvCoreFeatures: {
    byAgentId: () => '/agentDetail/featureFlags',
    byAccountId: () => '/accountDetail/featureFlags',
  },
  verifyUser: {
    findByEmail: (email) =>
      `/auth/findUsersByEmail?email=${encodeURIComponent(email)}`,
  },
  valuation: {
    get: () => `/valuationDetail`,
  },
  sendInvite: {
    post: () => '/invite/send',
  },

  myJourney: {
    myTransaction: {
      createNew: () => '/transaction',
      getActive: () => '/transaction/active',
      edit: (id) => `/transaction/${id}`,
      hasActive: () => `/transaction/hasActiveTransaction`,
      delete: (id) => `/transaction/${id}`,
    },
    myMove: {
      createNew: () => '/move',
      delete: (id) => `/move/${id}`,
      getActive: () => `/move/active`,
      editMove: (id) => `/move/${id}`,
      hasActive: () => `/move/hasActiveMove`,
      updateMoveTabItem: (moveId, tabItemId) =>
        `/move/${moveId}/checklistItems/${tabItemId}/checked`,
      updateMoveCompany: (moveId) => `/move/${moveId}/companyEstimate`,
      movingInventory: {
        createItem: (moveId) => `/move/${moveId}/inventoryItems`,
        getAllItems: (moveId) => `/move/${moveId}/inventoryItems`,
        editItem: (moveId, itemId) =>
          `/move/${moveId}/inventoryItems/${itemId}`,
        deleteItem: (moveId, itemId) =>
          `/move/${moveId}/inventoryItems/${itemId}`,
      },
    },
    myMaintenance: {
      getSeasons: () => `/maintenance`,
      post: (itemId) => `/maintenance/items/${itemId}/checked`,
    },
  },
};

export default apiRoutes;
