import 'intersection-observer';

import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { ThemeProvider } from '@emotion/react';

import { theme } from '../../constants';
import { NPSHead } from '../V2/NPS/NPSHead';
import { UnknownUser } from '../sign-in-form/UnknownUser';
import { useUserIdentify } from './hooks/useUserIdentify';
import { KnownUserRoutes } from './routes/KnownUserRoutes';
import { useAnalyticsKnownUserInit } from '../../features/analytics';
import { useMlsDetails } from '../../hooks/query/useMlsDetails';
import { BannerProvider } from '../../providers/BannerProvider';
import { GlobalStyling } from '../global-style/global-style.style';
import { useAgentDetails } from '../../hooks/query/useAgentDetails';
import { useAgentBranding } from '../../hooks/query/useAgentBranding';
import { AuthModalProvider } from '../../providers/AuthModalProvider';
import { MaterialThemeProvider } from '../../theme/MaterialThemeProvider';
import { useHomeownerByContact } from '../../hooks/useHomeownerByContact';
import { useCompanyBranding } from '../../hooks/query/useCompanyBranding';
import { useTokenData } from '../../providers/Authentication/useTokenData';
import { useIsEmbedded } from '../../hooks/query/useIsEmbeded';
import { ContactAgentProvider } from '../../providers/ContactAgentProvider';
import { NavigationHistoryProvider } from '../../providers/NavigationHistoryProvider';
import LockScreenOrientationModal from './LockScreenOrientationModal';

import 'react-toastify/dist/ReactToastify.css';
import '../../styles/reset.css';
import { TrackerWidgetHead } from '../V2/widget/TrackerWidgetHead';

function App() {
  useUserIdentify();
  useAnalyticsKnownUserInit();

  useAgentDetails();
  useHomeownerByContact();
  useMlsDetails();
  useCompanyBranding();

  const { data: tokenData } = useTokenData();
  const { data: brandingDetail } = useAgentBranding();
  const { data: companyDetail } = useCompanyBranding();
  const isEmbedded = useIsEmbedded();
  const themeDetail = isEmbedded ? companyDetail : brandingDetail;

  useEffect(() => {
    if (tokenData?.accountId) {
      Sentry.setTag('brokerage', tokenData.accountId);
    }
  }, [tokenData?.accountId]);

  return (
    <>
      <NPSHead />
      <TrackerWidgetHead />

      <NavigationHistoryProvider>
        <ThemeProvider theme={theme(themeDetail)}>
          <MaterialThemeProvider>
            <GlobalStyling />
            <AuthModalProvider>
              <ContactAgentProvider>
                {!tokenData?.contactId ? (
                  <UnknownUser />
                ) : (
                  <BannerProvider>
                    <KnownUserRoutes />
                  </BannerProvider>
                )}
                <LockScreenOrientationModal />
              </ContactAgentProvider>
            </AuthModalProvider>
          </MaterialThemeProvider>
        </ThemeProvider>
      </NavigationHistoryProvider>
    </>
  );
}

export default App;
